import products from '../../../sharedTypes/products';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.PAYMENTS,
  state: states.LOGIN,
  frozenConfig: {
    widget: {
      entityIdSelected: 'cl_banco_de_chile',
    },
  },
};
